import { AjaxApi } from './Request';

const Forms = {
    send: (data) => {
        const formData = new FormData();
        Object.keys(data).map((key) => {
            formData.append(key, data[key]);
        });
        return AjaxApi.post(`/include/handler.php`, formData);
    }
};

export default Forms;
