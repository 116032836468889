import LoaderMap from '@helpers/LoaderMap';

import './MapContact.scss';

declare global {
    interface Window {
        ymaps: any;
    }
}

const MapContact = (selector = 'map_full') => {
    const mapContainer: HTMLElement = document.getElementById(selector);

    if (!mapContainer) return;

    const getMapBaloons = () => {
        const map = new window.ymaps.Map(
            selector,
            {
                center: [61.250075, 73.364179],
                zoom: 17
            },
            {
                searchControlProvider: 'yandex#search'
            }
        );

        const placemark = new window.ymaps.Placemark(
            map.getCenter(),
            {
                hintContent: 'Отель "Metropolis"',
                balloonContent: 'г. Сургут, Набережный проспект, 13/1'
            },
            {
                iconLayout: 'default#image',
                iconImageHref: 'http://metropolis-hotel.ru/images/favicon.png',
                iconImageSize: [16, 16],
                iconImageOffset: [-0, -30]
            }
        );
        map.geoObjects.add(placemark);
        map.behaviors.disable('scrollZoom');
    };

    const { type } = mapContainer?.dataset;

    if (type && type == 'hover') {
        mapContainer.onmouseover = () => {
            if (!mapContainer.dataset.init) {
                mapContainer.dataset.init = 'true';
                LoaderMap().then(() => getMapBaloons());
            }
        };
        mapContainer.ontouchmove = () => {
            if (!mapContainer.dataset.init) {
                mapContainer.dataset.init = 'true';
                LoaderMap().then(() => getMapBaloons());
            }
        };
        return false;
    }

    LoaderMap().then(() => getMapBaloons());
};

export default MapContact;
