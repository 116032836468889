const phone = '8 (3462) 20-69-64';
const phoneLink = '+73462206964';
const phoneWhatsapp = '8 (3462) 55-08-20';
const phoneWhatsappLink = '+73462550820';
const email = 'info@metropolis-hotel.ru ';
const emailForm = email;
const mods = `Ежедневно с 10:00 до 20:00, по предварительной записи`;
const addressFull = `628404, г. Сургут, Набережный проспект, д.13/1. (Напротив Сургутнефтегазбанка)`;
const addressFullText = 'г. Сургут, Набережный проспект, д.13/1';
const addressShort = addressFullText;
const mapCoords = [59.931096, 30.432996];
const mapCoordsCenter = [59.931096, 30.437996];
const mapZoom = 16;

const baseConfig = () => ({
    SITE_NAME: 'Legal Fox',
    SITE_URL: 'http://new.metropolis-hotel.ru',
    SITE_PHONE: phone,
    SITE_PHONE_LINK: phoneLink,
    SITE_PHONE_WHATSAPP: phoneWhatsapp,
    SITE_PHONE_WHATSAPP_LINK: phoneWhatsappLink,
    SITE_EMAIL: email,
    SITE_EMAIL_FORM: emailForm,
    SITE_MODS: mods,
    SITE_ADDRESS: addressFull,
    SITE_ADDRESS_TEXT: addressFullText,
    SITE_ADDRESS_SHORT: addressShort,
    SITE_MAP: {
        center: window.innerWidth < 992 ? mapCoordsCenter : mapCoords,
        zoom: mapZoom,
        placemark: {
            coords: [59.931096, 30.437996],
            hintContent: 'Собственный значок метки',
            balloonContent: 'Это красивая метка'
        }
    },
    SITE_SOCIALS: [
        // {
        //     icon: 'vk',
        //     href: '!#'
        // },
        // {
        //     icon: 'instagram',
        //     href: '!#'
        // }
    ],
    SITE_ARTICLES: 2,
    SITE_SERVICES: 3,
    SITE_INDEX_PAGE: 4,
    SITE_CURRENCY: '₽'
});

export { baseConfig };
