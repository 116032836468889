import lozad from 'lozad';

const LazyLoad = (selector = '.lazyload') => {
    const observer = lozad(selector, {
        loaded: function (element) {
            let bg = element.getAttribute('data-bg');

            if (bg) {
                element.style.backgroundImage = 'url(' + bg + ')';
            }

            element.classList.remove('lazyload');
            element.classList.add('lazyloaded');
        }
    });
    observer.observe();
};

const LazyLoadStatic = (selectorName = '.js-content-lazyload img') => {
    [].forEach.call(document.querySelectorAll(selectorName), (el) => {
        el.style.display = 'block';
    });
    LazyLoad(selectorName);
};

export { LazyLoadStatic };
export default LazyLoad;
