import IMask from 'imask';

import Logo from '@kit/Logo';
import Preloader from '@kit/Preloader';

import LazyLoad, { LazyLoadStatic } from '@components/Lazyload';
import PrimarySlider from '@components/PrimarySlider';
import RoomsSlider from '@components/RoomsSlider';
import RoomsPageSlider from '@components/RoomsPageSlider';
import Travelline from '@components/Travelline';
import MapContact from '@components/MapContact';
import MicroModal, { ModalCreate } from '@kit/Modal';

import Forms from '@api/Forms';
import './Page.scss';

// Модальные окна
const modalConfig = {
    disableFocus: true,
    awaitCloseAnimation: true
};

/*const time3DOpen = () => {
    const idModal = `modal-3d-open`;

    ModalCreate({
        idModal,
        content: `
            <iframe 
                src="http://metropolis-hotel.ru/3d.html"
                id="bookingIframe"
                height="960"
                width="850"
            ></iframe>`,
        title: null
    });
    MicroModal.show(idModal, modalConfig);
};*/

const formInputMask = (selector = document.querySelector('#page')) => {
    selector.querySelectorAll('.phone-mask').forEach((input: HTMLInputElement) => {
        IMask(input, {
            mask: '+{7} (000) 000-00-00',
            lazy: false
        });
    });

    selector.querySelectorAll('.number-mask').forEach((input: HTMLInputElement) => {
        IMask(input, {
            mask: Number,
            min: input.dataset.min || 1,
            max: input.dataset.max || 999
        });
    });

    const date = new Date();
    selector.querySelectorAll('.date-mask').forEach((input: HTMLInputElement) => {
        IMask(input, {
            mask: Date,
            min: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
            lazy: false
        });
    });
};

const Page = () => {
    Logo();
    Preloader();
    LazyLoad();
    RoomsSlider();
    PrimarySlider();
    RoomsPageSlider();
    Travelline();
    MapContact();

    const page = document.querySelector('#page');
    const formFeedback = document.querySelector('#feedback');
    const hiddenNav = document.querySelector('#hidden_nav .btn');
    const buttonForms: NodeListOf<HTMLElement> = document.querySelectorAll('.js-button-form');
    const buttonContents: NodeListOf<HTMLElement> = document.querySelectorAll('.js-button-content');
    //const openTour = document.querySelectorAll('.js-open-tour');

    if (hiddenNav) {
        hiddenNav.addEventListener('click', (e) => {
            e.preventDefault();

            if (page.classList.contains('open_nav')) {
                page.classList.remove('open_nav');
                hiddenNav.classList.remove('current');
            } else {
                page.classList.add('open_nav');
                hiddenNav.classList.add('current');
            }
        });
    }

    const formHandler = (form) => {
        const fields = [];
        const inputs = {};

        formInputMask(form);

        form.addEventListener('submit', (e) => {
            e.preventDefault();
            let error = 0;
            form.querySelectorAll('b.req').forEach((item) => {
                const name = item.closest('td').querySelector('.text').getAttribute('name');
                fields.push(name);
            });
            form.querySelectorAll('input, textarea').forEach((input: HTMLInputElement) => {
                for (let x = 0; x < fields.length; x++) {
                    if (input.getAttribute('name') == fields[x]) {
                        if (!input.value) {
                            input.parentElement.classList.add('error');
                            error = 1;
                        } else {
                            input.parentElement.classList.remove('error');
                        }
                    }
                }
            });

            if (!error) {
                form.querySelectorAll('input, textarea').forEach((input: HTMLInputElement) => {
                    if (input.getAttribute('type') !== 'submit') {
                        inputs[input.getAttribute('name')] = input.value;
                    }
                });

                Forms.send(inputs)
                    .then(({ data }) => {
                        const idModal = `modal-${data?.module}`;
                        document.getElementById('modal-popup-container').innerHTML = '';

                        if (document.getElementById(idModal)) {
                            document.getElementById(idModal).parentElement.remove();
                        }

                        ModalCreate({
                            idModal,
                            content: data?.text,
                            title: data?.title
                        });
                        MicroModal.show(idModal, modalConfig);

                        if (data?.action !== 'error') {
                            form.reset();
                        }
                    })
                    .catch((err) => {
                        ModalCreate({
                            idModal: 'modal-feedback',
                            content: 'Произошла ошибка. Попробуйте повторить позднее',
                            title: 'Ошибка'
                        });
                        MicroModal.show('modal-feedback', modalConfig);
                    });
            }
        });
    };

    if (formFeedback) {
        const form = formFeedback.querySelector('form');
        formHandler(form);
    }

    buttonForms.forEach((button) => {
        const idModal = 'js-modal-form';
        button.addEventListener('click', () => {
            if (!document.getElementById(idModal)) {
                const { type, title, event } = button?.dataset;
                const typesSubmit = {
                    BIG: 'big_conference',
                    SMALL: 'small_conference',
                    SPA: 'spa',
                    TALKING: 'talking'
                };

                const typeSubmit = typesSubmit[type];

                if (typeSubmit) {
                    const eventSubmit = event ?? 'obratnayasvyas';

                    ModalCreate({
                        idModal,
                        content: `
                            <form onsubmit="yaCounter28041102.reachGoal('${eventSubmit}'); console.log('${eventSubmit}')" class="feedback" id="bookingForm">
                                <input type="hidden" name="booking" value="true">
                                <input type="hidden" name="form_type" value="${typeSubmit}">
                                <table style="width:100%">
                                    <tbody>
                                        <tr>
                                            <td colspan="2">
                                                <label for="name">Ваше имя<b class="req">*</b>:</label>
                                                <input class="text" name="s[name]" id="name" value="" required="true" type="text" style="width:100%">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <label for="phone">Телефон<b class="req">*</b>:</label>
                                                <input class="text phone-mask" name="s[phone]" id="phone" value="" required="true" type="text" style="width:100%">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2"><input type="submit" value="Отправить" class="submit"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        `,
                        title
                    });

                    const form = document.getElementById('bookingForm');
                    formHandler(form);
                }
            }

            MicroModal.show(idModal, modalConfig);
        });
    });

    buttonContents.forEach((button) => {
        const idModal = `Modal${button.dataset.id}`;
        button.addEventListener('click', () => {
            if (!document.getElementById(idModal)) {
                const { title, id } = button?.dataset;
                const content = document.getElementById(id);

                if (content) {
                    ModalCreate({
                        idModal,
                        content: `
                            <table style="width:100%;max-width:750px" class="modal-table">
                                <tbody>
                                    <tr>
                                        <td colspan="2">
                                            <div class="modal-table-content">
                                                ${content.innerHTML}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"><input data-micromodal-close type="button" value="Закрыть" class="submit"></td>
                                    </tr>
                                </tbody>
                            </table>
                        `,
                        title
                    });
                }
            }

            MicroModal.show(idModal, modalConfig);
        });
    });

    //openTour.forEach((item) => item.addEventListener('click', time3DOpen));

    window.addEventListener('load', () => LazyLoadStatic());
};

export default Page;
