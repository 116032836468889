const LoaderMap = (url = '') =>
    new Promise<void>((resolve, reject) => {
        const w = window as any;
        if (w.ymaps) return w.ymaps.ready(() => resolve());

        if (!w.ymapsScript) {
            url = url || 'https://api-maps.yandex.ru/2.1/?lang=ru-RU&#038;ver=1';

            w.ymapsScript = document.createElement('script');
            w.ymapsScript.async = true;
            w.ymapsScript.src = url;
            document.head.appendChild(w.ymapsScript);
        }

        w.ymapsScript.addEventListener('load', () => w.ymaps.ready(() => resolve()));

        w.ymapsScript.addEventListener('error', (err) => reject(err));
    });

export default LoaderMap;
