import Slider from '@components/Slider';

import './PrimarySlider.scss';

const PrimarySlider = (selectorName = '.slides') => {
    const mainSlider = document.querySelector(selectorName);

    if (mainSlider) {
        const mainWrapper: any = mainSlider.closest('.js-slider-main--wrapper');

        Slider({
            container: mainSlider,
            items: 1,
            slideBy: 1,
            loop: true,
            controls: true,
            controlsContainer: mainWrapper?.querySelector('.owl-nav') || false,
            prevButton: mainWrapper?.querySelector('.owl-prev') || false,
            nextButton: mainWrapper?.querySelector('.owl-next') || false,
            mouseDrag: true,
            preventScrollOnTouch: 'auto',
            autoplay: true,
            autoplayButtonOutput: false,
            navPosition: 'bottom'
        });
    }
};

export default PrimarySlider;
