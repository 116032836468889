import Slider from '@components/Slider';
import LazyLoad from '@components/Lazyload';

import './RoomsPageSlider.scss';

const RoomsPageSlider = (selectorName = '.js-slider-room--wrapper') => {
    const sliders: NodeListOf<any> = document.querySelectorAll(selectorName);

    sliders.forEach((slider) => {
        Slider({
            container: slider.querySelector('.customize'),
            items: 1,
            slideBy: 1,
            loop: false,
            controls: true,
            controlsContainer: slider?.querySelector('.owl-nav') || false,
            prevButton: slider?.querySelector('.owl-prev') || false,
            nextButton: slider?.querySelector('.owl-next') || false,
            mouseDrag: true,
            preventScrollOnTouch: 'auto',
            navPosition: 'bottom',
            navContainer: slider.querySelector('.customize-thumbnails'),
            navAsThumbnails: true,
            swipeAngle: false,
            speed: 400
        });

        LazyLoad('.js-slider-rooms .lazyload');
    });
};

export default RoomsPageSlider;
