import Slider from '@components/Slider';
import LazyLoad from '@components/Lazyload';

import './RoomsSlider.scss';

const RoomsSlider = (selectorName = '.js-slider-rooms') => {
    const roomsSlider: HTMLElement = document.querySelector(selectorName);

    if (roomsSlider) {
        const roomsWrapper: any = roomsSlider.closest('.js-slider-rooms--wrapper');

        Slider({
            container: roomsSlider,
            items: 3,
            slideBy: 1,
            loop: true,
            controls: true,
            controlsContainer: roomsWrapper?.querySelector('.owl-nav') || false,
            prevButton: roomsWrapper?.querySelector('.owl-prev') || false,
            nextButton: roomsWrapper?.querySelector('.owl-next') || false,
            mouseDrag: true,
            preventScrollOnTouch: 'auto',
            navPosition: 'bottom',
            responsive: {
                768: {
                    items: 3
                },
                575: {
                    items: 2
                },
                0: {
                    items: 1
                }
            }
        });

        LazyLoad('.js-slider-rooms .lazyload');
    }
};

export default RoomsSlider;
