import './Travelline.scss';

declare global {
    interface Window {
        travelline: any;
    }
}

const Travelline = (container = 'tl-search-form') => {
    const q = [
        ['setContext', 'TL-INT-metropolis-hotel', 'ru'],
        [
            'embed',
            'search-form',
            {
                container
            }
        ]
    ];
    const t = (window.travelline = window.travelline || {}),
        ti = (t.integration = t.integration || {});
    ti.__cq = ti.__cq ? ti.__cq.concat(q) : q;

    if (!ti.__loader) {
        ti.__loader = true;
        const d = window.document,
            p = d.location.protocol,
            s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = (p == 'https:' ? p : 'http:') + '//ibe.tlintegration.com/integration/loader.js';
        (d.getElementsByTagName('head')[0] || d.getElementsByTagName('body')[0]).appendChild(s);
    }
};

export default Travelline;
